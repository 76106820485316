import * as type from '../type';
import * as employer from '../../api/employer';

const state = {};

const actions = {
    employerAddOrUpdateBasic({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            employer.addOrUpdateBasic(
                payload.employer_id,
                payload.img_url,
                payload.name,
                payload.business,
                payload.scale,
                payload.position,
                payload.count,
                payload.phone
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    employerGetBasic({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            employer.getBasic(
                payload.employer_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    employerGetDescription({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            employer.getDescription(
                payload.employer_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    employerSetDescription({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            employer.setDescription(
                payload.employer_id,
                payload.description
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    employerGetUserList({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            employer.getUserList(
                payload.employer_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    employerSendMail({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            employer.sendMail(
                payload.employer_id,
                payload.email
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    employerGetService({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            employer.getService(
                payload.employer_id,
                payload.company_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    employerCreateAccount({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            employer.createAccount(
                payload.employer_id,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    employerGetContactList({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            employer.getContactList(
                payload.employer_id,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    employerGetContactById({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            employer.getContactById(
                payload.employer_id,
                payload.contact_id,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
};

const mutations = {
    [type.CHANGE_EMPLOYER]: (state, payload) => {
        localStorage.setItem('employer_id', payload.employer_id);
        localStorage.setItem('employer_name', payload.name);
        window.location.reload();
    }
};

export default {
    state,
    actions,
    mutations
}