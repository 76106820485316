import * as type from '../type';
import * as invoice from '../../api/invoice';

const state = {}

const actions = {
  invoiceGetList({
    state,
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      invoice.getList(
        payload.employer_id,
        payload.type
      ).then(res => {
        if (res.data.res_code == 1) {
          resolve(res.data.msg);
        } else {
          reject(res.data.msg);
        }
      }).catch(error => {
        console.error(error);
        reject();
      })
    })
  },
  invoiceGetDetail({
    state,
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      invoice.getDetail(
        payload.employer_id,
        payload.invoice_id
      ).then(res => {
        if (res.data.res_code == 1) {
          resolve(res.data.msg);
        } else {
          reject(res.data.msg);
        }
      }).catch(error => {
        console.error(error);
        reject();
      })
    })
  },
  invoiceApply({
    state,
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      invoice.apply(
        payload.employer_id,
        payload.invoice_id,
        payload.type,
        payload.company_address_id,
        payload.company_contact_id,
        payload.company_accuont_id,
        payload.more,
      ).then(res => {
        if (res.data.res_code == 1) {
          resolve(res.data.msg);
        } else {
          reject(res.data.msg);
        }
      }).catch(error => {
        console.error(error);
        reject();
      })
    })
  },
  invoiceCanApply({
    state,
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      invoice.canApply(
        payload.employer_id,
        payload.invoice_id
      ).then(res => {
        if (res.data.res_code == 1) {
          resolve(res.data.msg);
        } else {
          reject(res.data.msg);
        }
      }).catch(error => {
        console.error(error);
        reject();
      })
    })
  },
  invoiceGetInvoice({
    state,
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      invoice.getInvoice(
        payload.employer_id,
        payload.invoice_id
      ).then(res => {
        if (res.data.res_code == 1) {
          resolve(res.data.msg);
        } else {
          reject(res.data.msg);
        }
      }).catch(error => {
        console.error(error);
        reject();
      })
    })
  }
}

const mutations = {}

export default {
  state,
  actions,
  mutations
}