<template>
  <div class="my-avatar-root-container" v-if="isShow">
    <Avatar :size="size" v-if="!img_url" :style="{ background: color }">{{
      name[0]
    }}</Avatar>
    <Avatar :size="size" v-else :src="img_url"></Avatar>
  </div>
</template>

<script>
const colorList = ["#f56a00", "#7265e6", "#ffbf00", "#00a2ae"];
export default {
  props: {
    name: {
      type: String,
      default: "",
    },
    size: {
      type: Number,
      default: 32,
    },
    img_url: String,
  },
  data() {
    return {
      color: colorList[parseInt(Math.random() * colorList.length)],
      isShow: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.isShow = true;
    }, 200);
  },
};
</script>

<style scoped>
</style>