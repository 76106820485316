<template>
  <Drawer
    placement="right"
    :closable="true"
    v-model="drawerVisiable"
    class="my-drawer"
    :transfer="transfer"
    :inner="inner"
    :mask-closable="maskClosable"
  >
    <div class="drawer-root-container">
      <div class="drawer-header-container">
        <div class="drawer-header-left">
          <slot name="header-left"></slot>
        </div>

        <span style="font-size: 24px; color: #fff">{{ title }}</span>

        <div class="drawer-header-right">
          <slot name="header-right"></slot>
        </div>
      </div>

      <div
        class="drawer-main-container"
        :style="{ 'overflow-y': canScroll ? 'scroll' : 'hidden' }"
      >
        <slot name="main"></slot>
      </div>

      <div v-if="!hiddenFooter" class="drawer-footer-container">
        <slot name="footer"></slot>
      </div>
    </div>
  </Drawer>
</template>

<script>
export default {
  props: {
    title: String,
    canScroll: {
      type: Boolean,
      default: true,
    },
    hiddenFooter: {
      type: Boolean,
      default: false,
    },
    transfer: {
      type: Boolean,
      default: true,
    },
    inner: {
      type: Boolean,
      default: false,
    },
    maskClosable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      drawerVisiable: false,
    };
  },
  methods: {
    open() {
      this.drawerVisiable = true;
    },
    close() {
      this.drawerVisiable = false;
    },
  },
};
</script>

<style>
.my-drawer .ivu-drawer {
  position: fixed !important;
  top: 25px !important;
  right: 15px !important;
  width: 450px !important;
}
.my-drawer .ivu-drawer-content {
  height: calc(100% - 50px) !important;
  border-radius: 20px !important;
  overflow: hidden !important;
}
.my-drawer .ivu-drawer-body {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  padding: 0 !important;
  overflow: hidden !important;
}

.drawer-root-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
}
.drawer-header-container {
  position: relative;
  background-color: #2b202b;
  height: 80px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.drawer-header-left {
  position: absolute;
  top: 28px;
  left: 20px;
}
.drawer-header-right {
  position: absolute;
  top: 28px;
  right: 20px;
}
.drawer-main-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px 50px;
}
.drawer-footer-container {
  padding: 0 50px;
  height: 80px;
  border-top: 1px solid #eeeeee;
  display: flex;
  flex-direction: row;
  align-items: center;
}
</style>