import api from './config'

export function login(
    email,
    pwd
) {
    return api.post('user/login', {
        email,
        pwd
    })
}

export function sendRegistMail(
    email
) {
    return api.post('user/sendRegistMail', {
        email
    })
}

export function regist(
    guid,
    employer_name,
    employer_img_url,
    name,
    img_url,
    pwd,
    phone
) {
    return api.post('user/regist', {
        guid,
        employer_name,
        employer_img_url,
        name,
        img_url,
        pwd,
        phone
    })
}

export function getEmployer(
    guid
) {
    return api.post('user/getEmployer', {
        guid
    })
}

export function getUserInfo(
    guid
) {
    return api.post('user/getUserInfo', {
        guid
    })
}

export function bindEmployer(
    guid,
    name,
    img_url,
    pwd,
    phone
) {
    return api.post('user/bindEmployer', {
        guid,
        name,
        img_url,
        pwd,
        phone
    })
}

export function getEmployerList() {
    return api.post('user/getEmployerList', {});
}