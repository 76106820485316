<template>
  <div class="employer-root-container">
    <div class="employer-top-container">
      <my-avatar
        @click.native="changeEmployer(item)"
        class="employer-item-container"
        v-for="item in employerList"
        :key="item.employer_id"
        :name="item.name"
        :img_url="item.img_url"
        :size="60"
      ></my-avatar>
      <div class="employer-add-container" @click="addEmployer">
        <Icon type="md-add" :size="30" color="#fff" />
      </div>
    </div>
    <img @click="close" :src="icons.upArrow" class="employer-arrow" />
    <employer-drawer ref="employerDrawer" @refresh-employer-info="getInfo" />
  </div>
</template>

<script>
import * as type from "../../store/type";
import { mapActions } from "vuex";
import * as icon from "../../common/icon";
import MyAvatar from "../../components/MyAvatar.vue";
import EmployerDrawer from "../Employer/Drawer/EmployerDrawer";
export default {
  components: {
    "my-avatar": MyAvatar,
    "employer-drawer": EmployerDrawer,
  },
  data() {
    return {
      icons: icon,
      employerList: [],
    };
  },
  mounted() {
    this.getInfo();
  },
  methods: {
    ...mapActions({
      getEmployerListAction: "getEmployerList",
    }),
    close() {
      this.$emit("close");
    },
    getInfo() {
      this.getEmployerListAction()
        .then((res) => {
          this.employerList = res;
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
    addEmployer() {
      this.$refs.employerDrawer.open();
    },
    changeEmployer(item) {
      this.$store.commit(type.CHANGE_EMPLOYER, {
        employer_id: item.employer_id,
        name: item.name,
      });
    },
  },
};
</script>

<style scoped>
.employer-root-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  overflow: hidden;
}
.employer-top-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;
}
.employer-item-container {
  min-width: 60px;
  min-height: 60px;
  width: 60px;
  height: 60px;
  /* background-color: #333333; */
  border-radius: 6px;
  margin-bottom: 30px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}
.employer-add-container {
  min-width: 60px;
  min-height: 60px;
  width: 60px;
  height: 60px;
  border-radius: 6px;
  border: 1px solid #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.employer-arrow {
  width: 30px;
  height: 30px;
  margin-top: 20px;
  cursor: pointer;
}
</style>