import * as type from '../type';
import * as company from '../../api/company';

const state = {};

const actions = {
    getCompanyList({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            company.getCompanyList(
                payload.employer_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    companyCreate({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            company.create(
                payload.employer_id,
                payload.name,
                payload.img_url
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    getCompanyBasic({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            company.getCompanyBasic(
                payload.employer_id,
                payload.company_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    getCompanyInfo({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            company.getCompanyInfo(
                payload.employer_id,
                payload.company_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    getAccountList({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            company.getAccountList(
                payload.employer_id,
                payload.company_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    addOrUpdateAccount({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            company.addOrUpdateAccount(
                payload.employer_id,
                payload.company_id,
                payload.account_id,
                payload.bank_id,
                payload.sub_name,
                payload.num,
                payload.more,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    getAddressList({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            company.getAddressList(
                payload.employer_id,
                payload.company_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    addOrUpdateAddress({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            company.addOrUpdateAddress(
                payload.employer_id,
                payload.company_id,
                payload.address_id,
                payload.address
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    getContactList({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            company.getContactList(
                payload.employer_id,
                payload.company_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    addOrUpdateContact({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            company.addOrUpdateContact(
                payload.employer_id,
                payload.company_id,
                payload.contact_id,
                payload.name,
                payload.phone,
                payload.email,
                payload.img_url
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    goAuth({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            company.goAuth(
                payload.employer_id,
                payload.company_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    companyGetCompanyAssetList({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            company.getCompanyAssetList(
                payload.employer_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    companyGetContactListByCompanyIds({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            company.getContactListByCompanyIds(
                payload.employer_id,
                payload.company_id_arr
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    }
};

const mutations = {};

export default {
    state,
    actions,
    mutations
}