import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home/Home.vue'
import Login from '../views/Login/Login.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login'
  },
  {
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/Register/Register.vue'),
  },
  {
    path: '/register-auth',
    name: 'register-auth',
    component: () => import('../views/Register/RegisterAuth.vue'),
  },
  {
    path: '/home',
    name: 'home',
    component: Home,
    children: [
      {
        path: '/home',
        redirect: '/home/dashboard'
      },
      {
        path: '/home/dashboard',
        name: 'dashboard',
        component: () => import('../views/Dashboard/Dashboard.vue'),
      },
      {
        path: '/home/report',
        name: 'report',
        component: () => import('../views/Report/Report.vue'),
      },
      {
        path: '/home/app',
        name: 'app',
        component: () => import('../views/App/App.vue'),
      },
      {
        path: '/home/service',
        name: 'service',
        component: () => import('../views/Service/Service.vue'),
      },
      {
        path: '/home/contact',
        name: 'contact',
        component: () => import('../views/Contact/Contact.vue'),
      },
      {
        path: '/home/asset',
        name: 'asset',
        component: () => import('../views/AssetNew/AssetNew.vue'),
      },
      {
        path: '/home/trade',
        name: 'trade',
        component: () => import('../views/Trade/Trade.vue'),
      },
      {
        path: '/home/plan',
        name: 'plan',
        component: () => import('../views/Plan/Plan.vue'),
      },
      {
        path: '/home/queue',
        name: 'queue',
        component: () => import('../views/Queue/Queue.vue'),
      },
      {
        path: '/home/invoice',
        name: 'invoice',
        component: () => import('../views/Invoice/Invoice.vue'),
      },
      {
        path: '/home/project',
        name: 'project',
        component: () => import('../views/Project/Project.vue'),
        children: [
          {
            path: '/home/project/base/:id',
            name: 'project-base',
            component: () => import('../views/Project/Base.vue'),
          },
          {
            path: '/home/project/invite/:id',
            name: 'project-invite',
            component: () => import('../views/Project/Invite.vue'),
          },
          {
            path: '/home/project/accessories/:id',
            name: 'project-accessories',
            component: () => import('../views/Project/Accessories.vue'),
          },
        ]
      },
    ]
  },
  {
    path: '/home/queue-detail/:id',
    name: 'queue-detail',
    component: () => import('../views/Queue/QueueDetail.vue'),
  },
  {
    path: '/create-project',
    name: 'create-project',
    component: () => import('../views/Project/ProjectCreate.vue'),
  },
  {
    path: '/task-panel/:tid/:pid',
    name: 'task-panel',
    component: () => import('../views/Task/TaskPanel.vue'),
  },
  {
    path: '/task-detail/:tid/:pid',
    name: 'task-detail',
    component: () => import('../views/Task/TaskDetail.vue'),
    children: [
      {
        path: '/task-detail/task-info/:tid/:pid',
        name: 'task-info',
        component: () => import('../views/Task/TaskInfo.vue'),
      },
      {
        path: '/task-detail/task-list/:tid/:pid',
        name: 'task-list',
        component: () => import('../views/Task/TaskList.vue'),
      },
      {
        path: '/task-detail/task-active/:tid/:pid',
        name: 'task-active',
        component: () => import('../views/Task/TaskActive.vue'),
      },
      {
        path: '/task-detail/task-accessories/:tid/:pid',
        name: 'task-accessories',
        component: () => import('../views/Task/TaskAccessories.vue'),
      },
    ]
  },
  {
    path: '/task/:id',
    name: 'task',
    component: () => import('../views/Task/Task.vue'),
    children: [
      {
        path: '/task/task-dashboard/:id',
        name: 'task-dashboard',
        component: () => import('../views/Task/DashBoard.vue'),
      },
      {
        path: '/task/task-archive/:id',
        name: 'task-archive',
        component: () => import('../views/Task/Archive.vue'),
      },
      {
        path: '/task/task-newmilestone/:id',
        name: 'task-newmilestone',
        component: () => import('../views/Task/NewMilestone.vue'),
      },
      {
        path: '/task/task-milestone/:id',
        name: 'task-milestone',
        component: () => import('../views/Task/Milestone.vue'),
      },
    ]
  },
  {
    path: '/employer',
    name: 'employer',
    component: () => import('../views/Employer/Home.vue'),
    children: [
      {
        path: '/employer/employer-home',
        name: 'employer-home',
        component: () => import('../views/Employer/Employer.vue'),
      },
      {
        path: '/employer/employer-company/:company_id',
        name: 'employer-company',
        component: () => import('../views/Employer/Company.vue'),
      },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
