<template>
  <my-drawer ref="employerDrawer" title="编辑雇主信息">
    <template v-slot:main>
      <div style="display: flex; flex-direction: column">
        <my-single-image
          class="employer-form-image"
          ref="formImage"
          :before-upload="handleBeforeUpload"
          :img-url="employerForm.img_url"
        ></my-single-image>

        <my-input
          class="employer-form-item"
          ref="formName"
          title="名称"
          v-model="employerForm.name"
          :validate="nameValidate"
        />

        <my-input
          class="employer-form-item"
          ref="formBusiness"
          title="主营"
          v-model="employerForm.business"
        />

        <my-input
          class="employer-form-item"
          ref="formScale"
          title="规模"
          v-model="employerForm.scale"
        />

        <my-input
          class="employer-form-item"
          ref="formPosition"
          title="位置"
          v-model="employerForm.position"
        />

        <my-input
          class="employer-form-item"
          ref="formCount"
          title="人数"
          v-model="employerForm.count"
        />

        <my-input
          class="employer-form-item"
          ref="formPhone"
          title="手机号"
          :readonly="disable"
          v-model="employerForm.phone"
        />
      </div>
    </template>
    <template v-slot:footer>
      <Button size="large" type="primary" style="width: 100px" @click="submit"
        >保存</Button
      >
    </template>
  </my-drawer>
</template>

<script>
import * as oss from "../../../utils/ossHelper";
import MySingleImage from "../../../components/Form/MySingleImage";
import MyInput from "../../../components/Form/MyInput";
import MyDrawer from "../../../components/MyDrawer";
import { mapActions } from "vuex";
export default {
  components: {
    "my-drawer": MyDrawer,
    "my-input": MyInput,
    "my-single-image": MySingleImage,
  },
  data() {
    return {
      disable: false,
      employerForm: {
        employer_id: -1,
        img_url: null,
        business: "",
        name: "",
        scale: "",
        position: "",
        count: "",
        phone: "",
      },
    };
  },
  methods: {
    ...mapActions({
      employerGetBasicActionAction: "employerGetBasic",
      employerAddOrUpdateBasicAction: "employerAddOrUpdateBasic",
    }),
    open(id) {
      if (id) {
        this.disable = true;
        this.employerForm.employer_id = id;
        this.employerGetBasicActionAction({
          employer_id: id,
        })
          .then((res) => {
            this.employerForm.img_url = res.img_url;
            this.employerForm.business = res.business;
            this.employerForm.name = res.name;
            this.employerForm.scale = res.scale;
            this.employerForm.position = res.position;
            this.employerForm.count = res.count;
            this.employerForm.phone = res.phone;
          })
          .catch((error) => {
            this.$Message.error(error);
          });
      }
      this.$refs.employerDrawer.open();
    },
    submit() {
      Promise.all([this.$refs.formName.doValidate()])
        .then(() => {
          this.employerAddOrUpdateBasicAction(this.employerForm)
            .then((res) => {
              this.$Message.success("雇主信息保存成功");
              this.$emit("refresh-employer-info");
              this.$refs.employerDrawer.close();
            })
            .catch((error) => {
              this.$Message.error(error);
            });
        })
        .catch(() => {
          this.$Message.warning("表单中有错误项，请检查后修改并重新提交");
        });
    },
    /** 图片上传 */
    handleBeforeUpload(file) {
      this.$Spin.show();
      oss.upload(file, "employer").then((result) => {
        this.employerForm.img_url = result.fullPath;
        this.$Spin.hide();
      });
      return false;
    },
    /** 表单验证 */
    nameValidate(value) {
      return new Promise((resolve, reject) => {
        if (this.employerForm.name == "") {
          reject("请填写雇主名称");
        } else {
          resolve();
        }
      });
    },
  },
};
</script>

<style scoped>
.employer-form-image {
  width: 100px;
  height: 118px;
}
</style>