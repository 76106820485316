import axios from 'axios'
import { apiHost } from '../common/env';

var api = axios.create({
    baseURL: apiHost,
    withCredentials: true,
    timeout: 300000,
    headers: { "Content-Type": "application/json;charset=UTF-8" },
    transformRequest: [function (data, headers) {
        data.token = localStorage.getItem('token');
        return JSON.stringify(data);
    }],
    transformResponse: [function (data) {
        if (JSON.parse(data).res_code < 0) {
            console.log(data)
        }
        if (JSON.parse(data).res_code < -900) {
            localStorage.removeItem('token')
            window.location = '/login'
        }
        return JSON.parse(data)
    }]
})

export default api