import api from './config'

export function getBoardList(
  employer_id,
  project_id,
) {
  return api.post('task/getBoardList', {
    employer_id,
    project_id,
  });
}

export function addOrUpdateBoard(
  employer_id,
  project_id,
  task_board_id,
  img_url,
  name,
) {
  return api.post('task/addOrUpdateBoard', {
    employer_id,
    project_id,
    task_board_id,
    img_url,
    name,
  });
}

export function getBoardDetail(
  employer_id,
  project_id,
  task_board_id,
) {
  return api.post('task/getBoardDetail', {
    employer_id,
    project_id,
    task_board_id,
  });
}

export function setBoardState(
  employer_id,
  project_id,
  task_board_id,
  state,
) {
  return api.post('task/setBoardState', {
    employer_id,
    project_id,
    task_board_id,
    state,
  });
}

export function addOrUpdateTaskSet(
  employer_id,
  task_board_id,
  task_set_id,
  name,
) {
  return api.post('task/addOrUpdateTaskSet', {
    employer_id,
    task_board_id,
    task_set_id,
    name,
  });
}

export function getTaskSetList(
  employer_id,
  task_board_id,
) {
  return api.post('task/getTaskSetList', {
    employer_id,
    task_board_id,
  });
}

export function addTask(
  employer_id,
  task_set_id,
  name,
) {
  return api.post('task/addTask', {
    employer_id,
    task_set_id,
    name,
  });
}

export function getTaskDetail(
  employer_id,
  task_id,
) {
  return api.post('task/getTaskDetail', {
    employer_id,
    task_id,
  });
}

export function setName(
  employer_id,
  task_id,
  name,
) {
  return api.post('task/setName', {
    employer_id,
    task_id,
    name,
  });
}

export function inviteTaskMember(
  employer_id,
  task_id,
  member_id,
) {
  return api.post('task/inviteTaskMember', {
    employer_id,
    task_id,
    member_id,
  });
}

export function setDescription(
  employer_id,
  task_id,
  desc,
  start_time,
  end_time
) {
  return api.post('task/setDescription', {
    employer_id,
    task_id,
    desc,
    start_time,
    end_time
  });
}

export function setAddressArr(
  employer_id,
  task_id,
  address_arr,
) {
  return api.post('task/setAddressArr', {
    employer_id,
    task_id,
    address_arr,
  });
}

export function getTaskInventoryList(
  employer_id,
  task_id,
) {
  return api.post('task/getTaskInventoryList', {
    employer_id,
    task_id,
  });
}

export function addTaskInventory(
  employer_id,
  task_id,
  name,
) {
  return api.post('task/addTaskInventory', {
    employer_id,
    task_id,
    name,
  });
}

export function delTaskInventory(
  employer_id,
  task_id,
  task_inventory_id
) {
  return api.post('task/delTaskInventory', {
    employer_id,
    task_id,
    task_inventory_id
  });
}

export function addTaskInventoryDetail(
  employer_id,
  task_id,
  task_inventory_id,
  name,
) {
  return api.post('task/addTaskInventoryDetail', {
    employer_id,
    task_id,
    task_inventory_id,
    name,
  });
}

export function setTaskInventoryDetail(
  employer_id,
  task_id,
  task_inventory_id,
  task_inventory_detail_id,
  state
) {
  return api.post('task/setTaskInventoryDetail', {
    employer_id,
    task_id,
    task_inventory_id,
    task_inventory_detail_id,
    state
  });
}

export function archive(
  employer_id,
  task_id,
) {
  return api.post('task/archive', {
    employer_id,
    task_id,
  });
}

export function getArchiveList(
  employer_id,
  project_id,
) {
  return api.post('task/getArchiveList', {
    employer_id,
    project_id,
  });
}

export function getTaskActionList(
  employer_id,
  task_id,
) {
  return api.post('task/getTaskActionList', {
    employer_id,
    task_id,
  });
}

export function getTaskAccessories(
  employer_id,
  task_id,
) {
  return api.post('task/getTaskAccessories', {
    employer_id,
    task_id,
  });
}

export function addAccessories(
  employer_id,
  task_id,
  file_name,
  file_url,
  more,
  size,
) {
  return api.post('task/addAccessories', {
    employer_id,
    task_id,
    file_name,
    file_url,
    more,
    size,
  });
}

export function setTaskRewardType(
  employer_id,
  task_id,
  reward_type,
) {
  return api.post('task/setTaskRewardType', {
    employer_id,
    task_id,
    reward_type,
  });
}