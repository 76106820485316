import api from './config'

export function getList(
  employer_id,
  type,
) {
  return api.post('invoice/getList', {
    employer_id,
    type,
  })
}

export function getDetail(
  employer_id,
  invoice_id,
) {
  return api.post('invoice/getDetail', {
    employer_id,
    invoice_id,
  })
}

export function apply(
  employer_id,
  invoice_id,
  type,
  company_address_id,
  company_contact_id,
  company_accuont_id,
  more,
) {
  return api.post('invoice/apply', {
    employer_id,
    invoice_id,
    type,
    company_address_id,
    company_contact_id,
    company_accuont_id,
    more,
  })
}

export function canApply(
  employer_id,
  invoice_id,
) {
  return api.post('invoice/canApply', {
    employer_id,
    invoice_id,
  })
}

export function getInvoice(
  employer_id,
  invoice_id,
) {
  return api.post('invoice/getInvoice', {
    employer_id,
    invoice_id,
  })
}