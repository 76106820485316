import api from './config'


export function createHasStreamIdTempZip(
    start_time,
    end_time,
) {
    return api.post('temp/createHasStreamIdTempZip', {
        start_time,
        end_time,
    })
}
