import * as type from '../type';
import * as milestone from '../../api/milestone';

const state = {}

const actions = {
    milestoneAddOrUpdate({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            milestone.addOrUpdate(
                payload.milestone_id,
                payload.employer_id,
                payload.project_id,
                payload.name,
                payload.more,
                payload.excel,
                payload.accessories,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    milestoneGetExcelList({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            milestone.getExcelList(
                payload.employer_id,
                payload.milestone_id,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    milestoneGetExcelData({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            milestone.getExcelData(
                payload.employer_id,
                payload.project_id,
                payload.excel
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    milestoneSaveName({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            milestone.saveName(
                payload.employer_id,
                payload.milestone_id,
                payload.name
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve();
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    milestoneSaveMore({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            milestone.saveMore(
                payload.employer_id,
                payload.milestone_id,
                payload.more
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve();
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    milestoneGetAccessoriesList({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            milestone.getAccessoriesList(
                payload.employer_id,
                payload.milestone_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    milestoneAddAccessories({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            milestone.addAccessories(
                payload.employer_id,
                payload.milestone_id,
                payload.file_name,
                payload.file_url,
                payload.size
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    milestoneGetList({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            milestone.getList(
                payload.employer_id,
                payload.project_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    milestoneGetUserList({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            milestone.getUserList(
                payload.employer_id,
                payload.milestone_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    milestoneGetDetail({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            milestone.getDetail(
                payload.employer_id,
                payload.milestone_id,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    milestoneGetPayList({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            milestone.getPayList(
                payload.employer_id,
                payload.milestone_id_arr,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    milestoneDel({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            milestone.del(
                payload.employer_id,
                payload.milestone_id,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    }
};

const mutations = {};

export default {
    state,
    actions,
    mutations
}