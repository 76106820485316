import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'

import sel from './modules/sel'
import plan from './modules/plan'
import user from './modules/user'
import asset from './modules/asset'
import company from './modules/company'
import contact from './modules/contact'
import employer from './modules/employer'
import project from './modules/project'
import milestone from './modules/milestone'
import queue from './modules/queue'
import invoice from './modules/invoice'
import sms from './modules/sms'
import log from './modules/log'
import task from './modules/task'
import trade from './modules/trade'
import department from './modules/department'
import temp from './modules/temp'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  modules: {
    sel,
    plan,
    user,
    asset,
    company,
    contact,
    employer,
    project,
    milestone,
    queue,
    invoice,
    sms,
    log,
    task,
    trade,
    department,
    temp,
  },
  strict: debug,
  plugins: debug ? [createLogger()] : []
})
