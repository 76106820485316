import api from './config'

export function sendSMS(
    phone,
) {
    return api.post('sms/sendSMS', {
        phone,
    });
}

export function verifySMS(
    phone,
    sms_code
) {
    return api.post('sms/verifySMS', {
        phone,
        sms_code
    });
}