import * as type from '../type';
import * as sms from '../../api/sms';

const state = {}

const actions = {
  smsSendSMS({
    state,
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      sms.sendSMS(
        payload.phone
      ).then(res => {
        if (res.data.res_code == 1) {
          resolve(res.data.msg);
        } else {
          reject(res.data.msg);
        }
      }).catch(error => {
        console.error(error);
        reject();
      })
    })
  },
  smsVerifySMS({
    state,
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      sms.verifySMS(
        payload.phone,
        payload.sms_code
      ).then(res => {
        if (res.data.res_code == 1) {
          resolve(res.data.msg);
        } else {
          reject(res.data.msg);
        }
      }).catch(error => {
        console.error(error);
        reject();
      })
    })
  }
}

const mutations = {}

export default {
  state,
  actions,
  mutations
}