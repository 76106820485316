import * as type from '../type';
import * as plan from '../../api/plan';

const state = {};

const actions = {
    planGetReceiveMilestoneList({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            plan.getReceiveMilestoneList(
                payload.employer_id,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    planGetList({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            plan.getList(
                payload.employer_id,
                payload.state,
                payload.orderby_type,
                payload.pay_plan_id_arr
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    planGetCreate({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            plan.create(
                payload.employer_id,
                payload.project_id,
                payload.name,
                payload.more,
                payload.type,
                payload.milestone_id_arr,
                payload.excel,
                payload.accessories,
                payload.isByExcel,
                payload.zip_url
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    planSendQueue({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            plan.sendQueue(
                payload.employer_id,
                payload.pay_plan_id_arr
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    planSaveName({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            plan.saveName(
                payload.employer_id,
                payload.pay_plan_id,
                payload.name
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    planGetDetail({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            plan.getDetail(
                payload.employer_id,
                payload.pay_plan_id,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    planGetPayList({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            plan.getPayList(
                payload.employer_id,
                payload.pay_plan_id_arr
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    planGetUserList({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            plan.getUserList(
                payload.employer_id,
                payload.pay_plan_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    planGetExcelList({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            plan.getExcelList(
                payload.employer_id,
                payload.pay_plan_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    planGetAccessoriesList({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            plan.getAccessoriesList(
                payload.employer_id,
                payload.pay_plan_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    planAddAccessories({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            plan.addAccessories(
                payload.employer_id,
                payload.pay_plan_id,
                payload.file_name,
                payload.file_url,
                payload.size
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    planSetState({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            plan.setState(
                payload.employer_id,
                payload.pay_plan_id_arr,
                payload.state,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    planGetMilestoneActionList({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            plan.geetMilestoneActionList(
                payload.employer_id,
                payload.pay_plan_id,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    planGetMilestoneList({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            plan.geetMilestoneList(
                payload.employer_id,
                payload.pay_plan_id,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    planSyncData({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            plan.syncData(
                payload.employer_id,
                payload.pay_plan_id,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    planSaveMore({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            plan.saveMore(
                payload.employer_id,
                payload.pay_plan_id,
                payload.more
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    planAddMembers({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            plan.addMembers(
                payload.client_user_id_arr,
                payload.project_id
            ).then(res => {
                resolve(res.data.msg);
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    planVerifyExcelRepeat({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            plan.verifyExcelRepeat(
                payload.employer_id,
                payload.excel
            ).then(res => {
                resolve(res.data.msg);
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    planCreateTempSBYZip({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            plan.createTempSBYZip(
                payload.project_id,
                payload.pay_list
            ).then(res => {
                resolve(res.data.msg);
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    }
};

const mutations = {};

export default {
    state,
    actions,
    mutations
}