import * as type from '../type';
import * as task from '../../api/task';

const state = {}

const actions = {
    taskGetBoardList({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            task.getBoardList(
                payload.employer_id,
                payload.project_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    taskAddOrUpdateBoard({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            task.addOrUpdateBoard(
                payload.employer_id,
                payload.project_id,
                payload.task_board_id,
                payload.img_url,
                payload.name,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    taskSetBoardState({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            task.setBoardState(
                payload.employer_id,
                payload.project_id,
                payload.task_board_id,
                payload.state,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    taskAddOrUpdateTaskSet({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            task.addOrUpdateTaskSet(
                payload.employer_id,
                payload.task_board_id,
                payload.task_set_id,
                payload.name,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    taskGetTaskSetList({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            task.getTaskSetList(
                payload.employer_id,
                payload.task_board_id,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    taskGetBoardDetail({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            task.getBoardDetail(
                payload.employer_id,
                payload.project_id,
                payload.task_board_id,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    taskAddTask({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            task.addTask(
                payload.employer_id,
                payload.task_set_id,
                payload.name,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    taskSetName({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            task.setName(
                payload.employer_id,
                payload.task_id,
                payload.name,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    taskGetTaskDetail({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            task.getTaskDetail(
                payload.employer_id,
                payload.task_id,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    taskInviteTaskMember({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            task.inviteTaskMember(
                payload.employer_id,
                payload.task_id,
                payload.member_id,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    taskSetDescription({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            task.setDescription(
                payload.employer_id,
                payload.task_id,
                payload.desc,
                payload.start_time,
                payload.end_time
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    taskSetAddressArr({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            task.setAddressArr(
                payload.employer_id,
                payload.task_id,
                payload.address_arr,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    taskGetTaskInventoryList({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            task.getTaskInventoryList(
                payload.employer_id,
                payload.task_id,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    taskAddTaskInventory({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            task.addTaskInventory(
                payload.employer_id,
                payload.task_id,
                payload.name,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    taskDelTaskInventory({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            task.delTaskInventory(
                payload.employer_id,
                payload.task_id,
                payload.task_inventory_id,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    taskAddTaskInventoryDetail({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            task.addTaskInventoryDetail(
                payload.employer_id,
                payload.task_id,
                payload.task_inventory_id,
                payload.name,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    taskSetTaskInventoryDetail({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            task.setTaskInventoryDetail(
                payload.employer_id,
                payload.task_id,
                payload.task_inventory_id,
                payload.task_inventory_detail_id,
                payload.state,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    taskArchive({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            task.archive(
                payload.employer_id,
                payload.task_id,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    taskGetArchiveList({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            task.getArchiveList(
                payload.employer_id,
                payload.project_id,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    taskGetTaskActionList({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            task.getTaskActionList(
                payload.employer_id,
                payload.task_id,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    taskGetTaskAccessories({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            task.getTaskAccessories(
                payload.employer_id,
                payload.task_id,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    taskAddAccessories({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            task.addAccessories(
                payload.employer_id,
                payload.task_id,
                payload.file_name,
                payload.file_url,
                payload.more,
                payload.size,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    taskSetRewardType({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            task.setTaskRewardType(
                payload.employer_id,
                payload.task_id,
                payload.reward_type,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
}

const mutations = {};

export default {
    state,
    actions,
    mutations
}