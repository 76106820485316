import * as type from '../type';
import * as project from '../../api/project';

const state = {}

const actions = {
    projectGetList({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            project.getList(
                payload.employer_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    projectCreate({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            project.create(
                payload.employer_id,
                payload.company_id,
                payload.name,
                payload.more,
                payload.img_url,
                payload.tags,
                payload.start_time,
                payload.end_time,
                payload.address_arr,
                payload.pay_type,
                payload.accessories
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    projectGetBasic({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            project.getBasic(
                payload.employer_id,
                payload.project_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    projectSetMore({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            project.setMore(
                payload.employer_id,
                payload.project_id,
                payload.more
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve();
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    projectSetTags({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            project.setTags(
                payload.employer_id,
                payload.project_id,
                payload.tags
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    projectGetMemberJoinReq({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            project.getMemberJoinReq(
                payload.employer_id,
                payload.project_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    projectGetJoinedMemberList({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            project.getJoinedMemberList(
                payload.employer_id,
                payload.project_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    projectSetJoinState({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            project.setJoinState(
                payload.employer_id,
                payload.project_id,
                payload.client_user_id,
                payload.state,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    projectGetAccessoriesList({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            project.getAccessoriesList(
                payload.employer_id,
                payload.project_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    projectAddAccessories({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            project.addAccessories(
                payload.employer_id,
                payload.project_id,
                payload.file_name,
                payload.file_url,
                payload.more,
                payload.size
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    }
};

const mutations = {};

export default {
    state,
    actions,
    mutations
}