import api from './config'

export function addDepartment(
    department_arr,
    employer_id,
    project_id,
) {
    return api.post('department/addDepartment', {
        department_arr,
        employer_id,
        project_id
    })
}