import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import ViewUI from 'view-design';
import * as icon from './common/icon';
// import 'view-design/dist/styles/iview.css';
import './theme/index.less';

Vue.config.productionTip = false;

Vue.use(ViewUI);
// Vue.use(icon);

new Vue({
  router,
  data: function () {
    return {
      icons: icon
    }
  },
  store,
  render: h => h(App)
}).$mount('#app')