<template>
  <div class="menu-item-root-container">
    <div v-if="state" class="menu-item-selected"></div>
    <img :src="icon" style="z-index:2;width:22px;height:22px;" />
    <div style="font-size:15px;font-weight:500;color:#3C3C3C;margin-left:20px;">{{ label }}</div>
  </div>
</template>

<script>
export default {
  props: {
    state: Boolean,
    label: String,
    icon: String,
  },
};
</script>

<style scoped>
.menu-item-root-container {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  user-select: none;
  cursor: pointer;
  padding-left: 7px;
}
.menu-item-selected {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: #ffdf8d;
  position: absolute;
  top: 7px;
  left: 0px;
}
</style>