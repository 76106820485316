import api from './config'

export function addOrUpdate(
    milestone_id,
    employer_id,
    project_id,
    name,
    more,
    excel,
    accessories
) {
    return api.post('milestone/addOrUpdate', {
        milestone_id,
        employer_id,
        project_id,
        name,
        more,
        excel,
        accessories
    })
}

export function getExcelList(
    employer_id,
    milestone_id,
) {
    return api.post('milestone/getExcelList', {
        employer_id,
        milestone_id,
    })
}

export function getExcelData(
    employer_id,
    project_id,
    excel,
) {
    return api.post('milestone/getExcelData', {
        employer_id,
        project_id,
        excel,
    })
}

export function saveName(
    employer_id,
    milestone_id,
    name,
) {
    return api.post('milestone/saveName', {
        employer_id,
        milestone_id,
        name,
    })
}

export function saveMore(
    employer_id,
    milestone_id,
    more,
) {
    return api.post('milestone/saveMore', {
        employer_id,
        milestone_id,
        more,
    })
}

export function getAccessoriesList(
    employer_id,
    milestone_id,
) {
    return api.post('milestone/getAccessoriesList', {
        employer_id,
        milestone_id,
    })
}

export function addAccessories(
    employer_id,
    milestone_id,
    file_name,
    file_url,
    size,
) {
    return api.post('milestone/addAccessories', {
        employer_id,
        milestone_id,
        file_name,
        file_url,
        size,
    })
}

export function getList(
    employer_id,
    project_id,
) {
    return api.post('milestone/getList', {
        employer_id,
        project_id,
    })
}

export function getUserList(
    employer_id,
    milestone_id,
) {
    return api.post('milestone/getUserList', {
        employer_id,
        milestone_id,
    })
}

export function getDetail(
    employer_id,
    milestone_id,
) {
    return api.post('milestone/getDetail', {
        employer_id,
        milestone_id,
    })
}

export function getPayList(
    employer_id,
    milestone_id_arr,
) {
    return api.post('milestone/getPayList', {
        employer_id,
        milestone_id_arr,
    })
}

export function del(
    employer_id,
    milestone_id,
) {
    return api.post('milestone/del', {
        employer_id,
        milestone_id,
    })
}