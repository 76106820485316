import * as type from '../type';
import * as asset from '../../api/asset';

const state = {};

const actions = {
    assetGetBalanceByCompanyId({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            asset.getBalanceByCompanyId(
                payload.employer_id,
                payload.company_id,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    assetGetNotice({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            asset.getNotice(
                payload.employer_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    assetGotit({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            asset.gotit(
                payload.employer_id,
                payload.notice_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    assetGetBalance({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            asset.getBalance(
                payload.employer_id,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    assetGetCardLog({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            asset.getCardLog(
                payload.employer_id,
                payload.type,
                payload.page_index,
                payload.page_size,
                payload.search_content
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    assetGetCardList({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            asset.getCardList(
                payload.employer_id,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    assetGetCardLogDetail({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            asset.getCardLogDetail(
                payload.employer_id,
                payload.card_log_id,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    assetIsOpenCard({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            asset.isOpenCard(
                payload.employer_card_id,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    assetIsOpenCardByEmployerId({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            asset.isOpenCardByEmployerId(
                payload.employer_id,
                payload.company_id
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    assetGetCardDetail({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            asset.getCardDetail(
                payload.employer_card_id,
                payload.company_id,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    assetGetCardDetailByEmployerId({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            asset.getCardDetailByEmployerId(
                payload.employer_id,
                payload.company_id,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    assetGetPdf({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            asset.getPdf(
                payload.card_log_id,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
    assetTransferOut({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            asset.transferOut(
                payload.employer_id,
                payload.company_id,
                payload.salary_amount,
                payload.service_amount,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve();
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                reject(error);
            });
        })
    },
    assetGetTransferOutList({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            asset.getTransferOutList(
                payload.employer_id,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
};

const mutations = {};

export default {
    state,
    actions,
    mutations
}