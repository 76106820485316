import api from './config'

export function getNotice(
    employer_id
) {
    return api.post('card/getNotice', {
        employer_id
    })
}

export function getBalanceByCompanyId(
    employer_id,
    company_id
) {
    return api.post('card/getBalanceByCompanyId', {
        employer_id,
        company_id
    })
}

export function gotit(
    employer_id,
    notice_id
) {
    return api.post('card/gotit', {
        employer_id,
        notice_id
    })
}

export function getBalance(
    employer_id
) {
    return api.post('card/getBalance', {
        employer_id
    })
}

export function getCardLog(
    employer_id,
    type,
    page_index,
    page_size,
    search_content,
) {
    return api.post('card/getCardLog', {
        employer_id,
        type,
        page_index,
        page_size,
        search_content,
    })
}

export function getCardLogDetail(
    employer_id,
    card_log_id
) {
    return api.post('card/getCardLogDetail', {
        employer_id,
        card_log_id
    })
}

export function getCardList(
    employer_id
) {
    return api.post('card/getCardList', {
        employer_id
    })
}

export function isOpenCard(
    employer_card_id
) {
    return api.post('card/isOpenCard', {
        employer_card_id
    })
}

export function isOpenCardByEmployerId(
    employer_id
) {
    return api.post('card/isOpenCardByEmployerId', {
        employer_id
    })
}

export function getCardDetail(
    employer_card_id,
    company_id,
) {
    return api.post('card/getCardDetail', {
        employer_card_id,
        company_id,
    })
}

export function getCardDetailByEmployerId(
    employer_id,
    company_id
) {
    return api.post('card/getCardDetailByEmployerId', {
        employer_id,
        company_id
    })
}

export function getPdf(
    card_log_id
) {
    return api.post('card/getPdf', {
        card_log_id
    })
}

export function transferOut(
    employer_id,
    company_id,
    salary_amount,
    service_amount
) {
    return api.post('card/transferOut', {
        employer_id,
        company_id,
        salary_amount,
        service_amount
    })
}

export function getTransferOutList(
    employer_id
) {
    return api.post('card/getTransferOutList', {
        employer_id
    })
}