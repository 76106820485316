import * as type from '../type';
import * as department from '../../api/department';

const state = {};

const actions = {
    departmentAdd({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            department.addDepartment(
                payload.department_arr,
                payload.employer_id,
                payload.project_id,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    },
};

const mutations = {};

export default {
    state,
    actions,
    mutations,
}