import * as type from '../type';
import * as temp from '../../api/temp';

const state = {}

const actions = {
    tempCreateHasStreamIdTempZip({
        state,
        commit
    }, payload) {
        return new Promise((resolve, reject) => {
            temp.createHasStreamIdTempZip(
                payload.start_time,
                payload.end_time,
            ).then(res => {
                if (res.data.res_code == 1) {
                    resolve(res.data.msg);
                } else {
                    reject(res.data.msg);
                }
            }).catch(error => {
                console.error(error);
                reject(error);
            })
        })
    }
}

const mutations = {};

export default {
    state,
    actions,
    mutations,
}