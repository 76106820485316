<template>
  <div class="tool-bar-root-container">
    <div class="tool-bar-right-container">
      <!-- 图标、信息 -->
      <img :src="icons.home" style="cursor:pointer;" @click="homeClick" />
      <div class="tool-bar-info-container">
        <div style="text-align:right;">
          <div
            style="font-size: 13px;font-weight: 500;color: #494949;width: 120px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;"
          >{{ companyName }}</div>
          <div style="font-size: 12px;color: #646464;">{{ managerName }}</div>
        </div>
        <my-avatar :name="managerName" :img_url="imgUrl" style="margin: 0 10px;"></my-avatar>
        <Poptip placement="bottom-end">
          <Icon type="ios-arrow-down" style="cursor:pointer;" />
          <div slot="content">
            <div style="cursor:pointer;text-align:center;" @click="logout">退出登录</div>
          </div>
        </Poptip>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import * as icon from "../../common/icon";
import MyAvatar from "../../components/MyAvatar";
const colorList = ["#f56a00", "#7265e6", "#ffbf00", "#00a2ae"];
export default {
  components: {
    "my-avatar": MyAvatar,
  },
  data() {
    return {
      icons: icon,
      imgUrl: "",
      companyName: "",
      managerName: "",
      color: colorList[parseInt(Math.random() * colorList.length)],
    };
  },
  mounted() {
    this.imgUrl = localStorage.getItem("img_url");
    this.companyName = localStorage.getItem("employer_name");
    this.managerName = localStorage.getItem("user_name");
  },
  methods: {
    ...mapActions({
      logoutAction: "logout",
    }),
    logout() {
      this.logoutAction();
      this.$router.push({ name: "login" });
    },
    homeClick() {
      this.$router.push({
        name: "employer-home",
      });
    },
  },
};
</script>

<style scoped>
.tool-bar-root-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  min-height: 80px;
}
.tool-bar-right-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.tool-bar-info-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-right: 60px;
}
</style>