import api from './config'

export function getCompanyList(
    employer_id
) {
    return api.post('company/getCompanyList', {
        employer_id
    })
}

export function create(
    employer_id,
    name,
    img_url
) {
    return api.post('company/create', {
        employer_id,
        name,
        img_url
    })
}

export function getCompanyBasic(
    employer_id,
    company_id
) {
    return api.post('company/getCompanyBasic', {
        employer_id,
        company_id
    })
}

export function getCompanyInfo(
    employer_id,
    company_id,
) {
    return api.post('company/getCompanyInfo', {
        employer_id,
        company_id
    })
}

export function getAccountList(
    employer_id,
    company_id,
) {
    return api.post('company/getAccountList', {
        employer_id,
        company_id
    })
}

export function addOrUpdateAccount(
    employer_id,
    company_id,
    account_id,
    bank_id,
    sub_name,
    num,
    more,
) {
    return api.post('company/addOrUpdateAccount', {
        employer_id,
        account_id,
        company_id,
        bank_id,
        sub_name,
        num,
        more,
    })
}

export function getAddressList(
    employer_id,
    company_id,
) {
    return api.post('company/getAddressList', {
        employer_id,
        company_id
    })
}

export function addOrUpdateAddress(
    employer_id,
    company_id,
    address_id,
    address
) {
    return api.post('company/addOrUpdateAddress', {
        employer_id,
        address_id,
        company_id,
        address
    })
}

export function getContactList(
    employer_id,
    company_id,
) {
    return api.post('company/getContactList', {
        employer_id,
        company_id
    })
}

export function addOrUpdateContact(
    employer_id,
    company_id,
    contact_id,
    name,
    phone,
    email,
    img_url
) {
    return api.post('company/addOrUpdateContact', {
        employer_id,
        company_id,
        contact_id,
        name,
        phone,
        email,
        img_url
    })
}

export function goAuth(
    employer_id,
    company_id
) {
    return api.post('company/goAuth', {
        employer_id,
        company_id
    })
}

export function getCompanyAssetList(
    employer_id
) {
    return api.post('company/getCompanyAssetList', {
        employer_id
    })
}

export function getContactListByCompanyIds(
    employer_id,
    company_id_arr
) {
    return api.post('company/getContactListByCompanyIds', {
        employer_id,
        company_id_arr
    });
}