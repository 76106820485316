import api from './config'

export function addOrUpdateBasic(
    employer_id,
    img_url,
    name,
    business,
    scale,
    position,
    count,
    phone
) {
    return api.post('employer/addOrUpdateBasic', {
        employer_id,
        img_url,
        name,
        business,
        scale,
        position,
        count,
        phone
    })
}

export function getBasic(
    employer_id
) {
    return api.post('employer/getBasic', {
        employer_id
    })
}

export function getDescription(
    employer_id,
) {
    return api.post('employer/getDescription', {
        employer_id
    })
}

export function setDescription(
    employer_id,
    description
) {
    return api.post('employer/setDescription', {
        employer_id,
        description
    })
}

export function getUserList(
    employer_id
) {
    return api.post('employer/getUserList', {
        employer_id
    })
}

export function sendMail(
    employer_id,
    email
) {
    return api.post('employer/sendMail', {
        employer_id,
        email
    })
}

export function getService(
    employer_id,
    company_id
) {
    return api.post('employer/getService', {
        employer_id,
        company_id
    })
}

export function createAccount(
    employer_id,
) {
    return api.post('employer/createAccount', {
        employer_id,
    })
}

export function getContactList(
    employer_id,
) {
    return api.post('employer/getContactList', {
        employer_id,
    })
}

export function getContactById(
    employer_id,
    contact_id,
) {
    return api.post('employer/getContactById', {
        employer_id,
        contact_id,
    })
}


