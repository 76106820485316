
import { ossHost, bucketName } from '../common/env';
import * as oss from '../api/oss';
import axios from "axios";

export function changeUnit(limit) {
    var size = "";
    if (limit < 0.1 * 1024) {                            //小于0.1KB，则转化成B
        size = limit + "B"
    } else if (limit < 0.1 * 1024 * 1024) {            //小于0.1MB，则转化成KB
        size = (limit / 1024).toFixed(2) + "KB"
    } else if (limit < 0.1 * 1024 * 1024 * 1024) {        //小于0.1GB，则转化成MB
        size = (limit / (1024 * 1024)).toFixed(2) + "MB"
    } else {                                            //其他转化成GB
        size = (limit / (1024 * 1024 * 1024)).toFixed(2) + "GB"
    }

    var sizeStr = size + "";                        //转成字符串
    var index = sizeStr.indexOf(".");                    //获取小数点处的索引
    var dou = sizeStr.substr(index + 1, 2)            //获取小数点后两位的值
    if (dou == "00") {                                //判断后两位是否为00，如果是则删除00                
        return sizeStr.substring(0, index) + sizeStr.substr(index + 3, 2)
    }
    return size;
}

export function upload(file, dirName) {
    return new Promise((resolve, reject) => {
        var date = new Date();
        date = date.toGMTString();
        oss.getSign(file.type, date, bucketName, dirName, file.name, "POST")
            .then(res => {
                if (res.data.res_code == 1) {
                    const formData = new FormData();
                    formData.append("key", res.data.msg.filename);
                    formData.append("OSSAccessKeyId", res.data.msg.accessKeyID);
                    formData.append("success_action_status", "200");
                    formData.append("signature", res.data.msg.sign);
                    formData.append("policy", res.data.msg.policyBase64);
                    formData.append("file", file);
                    axios({
                        method: "POST",
                        url: encodeURI(ossHost),
                        data: formData
                    })
                        .then(ossRes => {
                            if (ossRes.statusText == "OK") {
                                let result = {};
                                result.name = file.name;
                                result.path = res.data.msg.filename;
                                result.fullPath = encodeURI(ossHost) + res.data.msg.filename;
                                resolve(result)
                            } else {
                                reject();
                            }
                        })
                        .catch(error => {
                            console.log(error);
                            reject();
                        });
                } else {
                    reject();
                }
            })
            .catch(error => {
                console.log(error);
                reject();
            });
    });
}