import api from './config'

export function getList(
    employer_id
) {
    return api.post('contact/getList', {
        employer_id
    })
}

export function getDetail(
    employer_id,
    client_user_id
) {
    return api.post('contact/getDetail', {
        employer_id,
        client_user_id
    })
}

export function getPayLog(
    employer_id,
    client_user_id
) {
    return api.post('contact/getPayLog', {
        employer_id,
        client_user_id
    })
}

export function getProjectList(
    employer_id,
    client_user_id
) {
    return api.post('contact/getProjectList', {
        employer_id,
        client_user_id
    })
}