import api from './config'

export function getReceiveMilestoneList(
    employer_id,
) {
    return api.post('pay_plan/getReceiveMilestoneList', {
        employer_id,
    })
}

export function getList(
    employer_id,
    state,
    orderby_type,
    pay_plan_id_arr
) {
    return api.post('pay_plan/getList', {
        employer_id,
        state,
        orderby_type,
        pay_plan_id_arr
    })
}

export function create(
    employer_id,
    project_id,
    name,
    more,
    type,
    milestone_id_arr,
    excel,
    accessories,
    isByExcel,
    zip_url
) {
    return api.post('pay_plan/create', {
        employer_id,
        project_id,
        name,
        more,
        type,
        milestone_id_arr,
        excel,
        accessories,
        isByExcel,
        zip_url
    })
}

export function sendQueue(
    employer_id,
    pay_plan_id_arr
) {
    return api.post('pay_plan/sendQueue', {
        employer_id,
        pay_plan_id_arr
    })
}

export function saveName(
    employer_id,
    pay_plan_id,
    name
) {
    return api.post('pay_plan/saveName', {
        employer_id,
        pay_plan_id,
        name
    })
}

export function getDetail(
    employer_id,
    pay_plan_id
) {
    return api.post('pay_plan/getDetail', {
        employer_id,
        pay_plan_id
    })
}

export function getPayList(
    employer_id,
    pay_plan_id_arr
) {
    return api.post('pay_plan/getPayList', {
        employer_id,
        pay_plan_id_arr
    })
}

export function getUserList(
    employer_id,
    pay_plan_id
) {
    return api.post('pay_plan/getUserList', {
        employer_id,
        pay_plan_id
    })
}

export function getExcelList(
    employer_id,
    pay_plan_id
) {
    return api.post('pay_plan/getExcelList', {
        employer_id,
        pay_plan_id
    })
}

export function getAccessoriesList(
    employer_id,
    pay_plan_id
) {
    return api.post('pay_plan/getAccessoriesList', {
        employer_id,
        pay_plan_id
    })
}

export function addAccessories(
    employer_id,
    pay_plan_id,
    file_name,
    file_url,
    size
) {
    return api.post('pay_plan/addAccessories', {
        employer_id,
        pay_plan_id,
        file_name,
        file_url,
        size
    })
}

export function setState(
    employer_id,
    pay_plan_id_arr,
    state
) {
    return api.post('pay_plan/setState', {
        employer_id,
        pay_plan_id_arr,
        state
    })
}

export function geetMilestoneActionList(
    employer_id,
    pay_plan_id
) {
    return api.post('pay_plan/getMilestoneActionList', {
        employer_id,
        pay_plan_id
    })
}

export function geetMilestoneList(
    employer_id,
    pay_plan_id
) {
    return api.post('pay_plan/getMilestoneList', {
        employer_id,
        pay_plan_id
    })
}

export function syncData(
    employer_id,
    pay_plan_id
) {
    return api.post('pay_plan/syncData', {
        employer_id,
        pay_plan_id
    })
}

export function saveMore(
    employer_id,
    pay_plan_id,
    more
) {
    return api.post('pay_plan/saveMore', {
        employer_id,
        pay_plan_id,
        more
    })
}

export function addMembers(
    client_user_id_arr,
    project_id
) {
    return api.post('pay_plan/addMembers', {
        client_user_id_arr,
        project_id
    })
}

export function verifyExcelRepeat(
    employer_id,
    excel
) {
    return api.post('pay_plan/verifyExcelRepeat', {
        employer_id,
        excel
    })
}

export function createTempSBYZip(
    project_id,
    pay_list
) {
    return api.post('pay_plan/createTempSBYZip', {
        project_id,
        pay_list
    })
}