import api from './config'

export function getSign(content_type, date, bucket, dir, filename, type) {
    return api.post('oss/getSign', {
        content_type: content_type,
        date: date,
        bucket: bucket,
        dir: dir,
        filename: filename,
        type: type
    });
}