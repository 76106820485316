import api from './config'

export function getList(
    employer_id
) {
    return api.post('project/getList', {
        employer_id
    })
}

export function create(
    employer_id,
    company_id,
    name,
    more,
    img_url,
    tags,
    start_time,
    end_time,
    address_arr,
    pay_type,
    accessories
) {
    return api.post('project/create', {
        employer_id,
        company_id,
        name,
        more,
        img_url,
        tags,
        start_time,
        end_time,
        address_arr,
        pay_type,
        accessories
    })
}

export function getBasic(
    employer_id,
    project_id
) {
    return api.post('project/getBasic', {
        employer_id,
        project_id
    })
}

export function setMore(
    employer_id,
    project_id,
    more
) {
    return api.post('project/setMore', {
        employer_id,
        project_id,
        more
    })
}

export function setTags(
    employer_id,
    project_id,
    tags
) {
    return api.post('project/setTags', {
        employer_id,
        project_id,
        tags
    })
}

export function getMemberJoinReq(
    employer_id,
    project_id
) {
    return api.post('project/getMemberJoinReq', {
        employer_id,
        project_id
    })
}

export function getJoinedMemberList(
    employer_id,
    project_id
) {
    return api.post('project/getJoinedMemberList', {
        employer_id,
        project_id
    })
}

export function setJoinState(
    employer_id,
    project_id,
    client_user_id,
    state,
) {
    return api.post('project/setJoinState', {
        employer_id,
        project_id,
        client_user_id,
        state,
    })
}

export function getAccessoriesList(
    employer_id,
    project_id
) {
    return api.post('project/getAccessoriesList', {
        employer_id,
        project_id
    })
}

export function addAccessories(
    employer_id,
    project_id,
    file_name,
    file_url,
    more,
    size
) {
    return api.post('project/addAccessories', {
        employer_id,
        project_id,
        file_name,
        file_url,
        more,
        size
    })
}