<template>
  <div class="login-root-container">
    <logo />
    <span style="color: #3e3e3e; font-size: 23px"
      >👏欢迎登录您的游用工账户</span
    >

    <div class="login-input-container" style="margin-top: 80px">
      <span class="login-input-label">邮箱地址</span>
      <Input
        size="large"
        v-model="email"
        placeholder="输入您的邮箱信息"
        style="width: 400px"
      />
    </div>

    <div class="login-input-container" style="margin-top: 20px">
      <span class="login-input-label">密码</span>
      <Input
        type="password"
        size="large"
        v-model="password"
        placeholder="您的登录密码"
        style="width: 400px"
        @keyup.enter.native="login"
      />
    </div>

    <Button class="login-btn" size="large" type="primary" @click="login"
      >登录</Button
    >

    <div class="login-bottom-container">
      <Checkbox v-model="isRemember">记住我的登录状态</Checkbox>
      <Button
        style="font-size: 15px; color: #3c739f"
        type="text"
        @click="regist"
        >注册新用户</Button
      >
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import logo from "../../components/logo";

export default {
  components: {
    logo: logo,
  },
  data() {
    return {
      email: "",
      password: "",
      isRemember: true,
    };
  },
  mounted() {
    let isRemember = localStorage.getItem("remember");
    if (isRemember == "true") {
      let token = localStorage.getItem("token");
      if (token) {
        this.$router.push({ name: "dashboard" });
      }
    }
  },
  methods: {
    ...mapActions({
      loginAction: "login",
    }),
    regist() {
      this.$router.push({ name: "register" });
    },
    login() {
      if (this.email == "") {
        this.$Message.error("请输入邮箱");
        return;
      }
      if (this.password == "") {
        this.$Message.error("请输入密码");
        return;
      }
      if (
        !/^([a-zA-Z\d])(\w|\-)+@[a-zA-Z\d]+\.[a-zA-Z]{2,4}$/.test(this.email)
      ) {
        this.$Message.error("请输入正确的邮箱");
        return;
      }

      localStorage.setItem("remember", this.isRemember);

      this.loginAction({
        email: this.email,
        pwd: this.password,
      })
        .then((res) => {
          this.$router.push({ name: "dashboard" });
        })
        .catch((error) => {
          this.$Message.error(error);
        });
    },
  },
};
</script>

<style>
.login-root-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}
.login-input-container {
  display: flex;
  flex-direction: column;
}
.login-input-label {
  color: #111111;
  font-size: 15px;
  margin-bottom: 15px;
}
.login-btn {
  width: 400px;
  margin-top: 50px;
}
.login-bottom-container {
  width: 400px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 100px;
}
</style>