<template>
  <div class="home-root-container">
    <div
      class="home-employer-container"
      :style="{ width: showEmployer ? '90px' : '0px' }"
    >
      <employer-bar
        @close="
          () => {
            showEmployer = false;
          }
        "
      />
    </div>
    <div class="home-menu-container">
      <div class="home-menu-logo-container">
        <logo size="small" />
        <img
          @click="
            () => {
              showEmployer = !showEmployer;
            }
          "
          class="home-menu-refresh"
          :src="icons.refresh"
        />
      </div>

      <div class="home-menu-scroll-wrapper">
        <div class="home-menu-top-container">
          <menu-item
            class="home-menu-item"
            @click.native="navigate('dashboard')"
            :state="currentMenu == 'dashboard'"
            label="工作台"
            :icon="icons.dashboard"
          />
          <menu-item
            class="home-menu-item"
            @click.native="navigate('contact')"
            :state="currentMenu == 'contact'"
            label="通讯录"
            :icon="icons.contact"
          />
          <menu-item
            class="home-menu-item"
            @click.native="navigate('trade')"
            :state="currentMenu == 'trade'"
            label="交易查询"
            :icon="icons.tradeSearch"
          />
          <!-- <menu-item
            class="home-menu-item"
            @click.native="navigate('service')"
            :state="currentMenu == 'service'"
            label="服务"
            :icon="icons.service"
          />
          <menu-item
            class="home-menu-item"
            @click.native="navigate('app')"
            :state="currentMenu == 'app'"
            label="连接"
            :icon="icons.app"
          />
          <menu-item
            class="home-menu-item"
            @click.native="navigate('report')"
            :state="currentMenu == 'report'"
            label="报告"
            :icon="icons.report"
          /> -->
        </div>
        <span class="home-menu-label" style="margin-top: 20px">财务处理</span>
        <div class="home-menu-center-container">
          <menu-item
            class="home-menu-item"
            @click.native="navigate('asset')"
            :state="currentMenu == 'asset'"
            label="账户资产"
            :icon="icons.asset"
          />
          <menu-item
            class="home-menu-item"
            @click.native="navigate('plan')"
            :state="currentMenu == 'plan'"
            label="付款计划"
            :icon="icons.plan"
          />
          <menu-item
            class="home-menu-item"
            @click.native="navigate('queue')"
            :state="currentMenu == 'queue'"
            label="处理队列"
            :icon="icons.queue"
          />
          <menu-item
            class="home-menu-item"
            @click.native="navigate('invoice')"
            :state="currentMenu == 'invoice'"
            label="获取发票"
            :icon="icons.invoice"
          />
        </div>
        <div class="home-menu-project-container">
          <span class="home-menu-label">发布的项目/工作</span>
          <img
            @click="addProject"
            class="home-menu-add-project"
            :src="icons.add"
          />
        </div>
        <div class="home-menu-project-list">
          <span
            @click="goProject(item.project_id)"
            class="home-menu-project-item"
            v-for="item in projectList"
            :key="item.project_id"
            >{{ item.name }}</span
          >
        </div>
      </div>
    </div>
    <div class="home-main-container">
      <div class="home-header-container">
        <tool-bar></tool-bar>
      </div>
      <transition name="fade" mode="out-in">
        <div class="router-scroll">
          <router-view />
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import logo from "../../components/logo";
import toolBar from "./ToolBar";
import employerBar from "./EmployerBar";
import menuItem from "./MenuItem";
import * as icon from "../../common/icon";

export default {
  name: "Home",
  components: {
    logo: logo,
    "tool-bar": toolBar,
    "menu-item": menuItem,
    "employer-bar": employerBar,
  },
  data() {
    return {
      icons: icon,
      currentMenu: "",
      showEmployer: false,
      projectList: [],
    };
  },
  mounted() {
    this.$Notice.config({
      duration: 0,
    });

    // 路由跳转后控制选中
    this.$router.afterEach((to, from) => {
      this.currentMenu = to.name;
    });

    // 刷新页面后控制选中
    this.currentMenu = this.$router.currentRoute.name;

    this.projectGetListAction({
      employer_id: localStorage.getItem("employer_id"),
    }).then((res) => {
      this.projectList = res;
    });
  },
  methods: {
    ...mapActions({
      projectGetListAction: "projectGetList",
    }),
    navigate(name) {
      if (this.$router.currentRoute.name == name) {
        return;
      }
      this.$router.push({
        name: name,
      });
    },
    addProject() {
      this.$router.push({
        name: "create-project",
      });
    },
    goProject(id) {
      if (
        (this.$router.currentRoute.name == "project-base" ||
          this.$router.currentRoute.name == "project-invite" ||
          this.$router.currentRoute.name == "project-accessories") &&
        this.$router.currentRoute.params.id == id
      ) {
        return;
      }
      this.$router.push({
        name: "project-base",
        params: {
          id: id,
        },
      });
    },
  },
};
</script>

<style scoped>
.home-root-container {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: row;
  background-color: #f8f8f8;
}
/* router-view 样式 */
.home-main-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}
.home-header-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 80px;
  background-color: #f9f9f9;
}
.router-scroll {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
/* 菜单 */
.home-menu-container {
  display: flex;
  flex-direction: column;
  width: 250px;
  height: 100vh;
  background-color: #f9f9f9;
  padding: 20px 20px 0 20px;
}
.home-menu-scroll-wrapper {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}
.home-menu-logo-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.home-menu-refresh {
  margin-bottom: 10px;
  margin-left: 10px;
  cursor: pointer;
}
.home-menu-top-container {
  display: flex;
  flex-direction: column;
  margin-top: 40px;
}
.home-menu-item {
  margin-bottom: 20px;
}
.home-menu-center-container {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}
.home-menu-label {
  color: #919191;
  font-size: 12px;
}
.home-menu-project-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.home-menu-add-project {
  cursor: pointer;
}
.home-employer-container {
  transition: all 0.2s ease;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  height: 100vh;
  background: #656565;
}
.home-menu-project-list {
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  padding: 20px;
}
.home-menu-project-item {
  display: block;
  width: 170px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 15px;
  font-weight: bold;
  margin-bottom: 20px;
  cursor: pointer;
  user-select: none;
}
</style>