<template>
  <div ref="root" class="input-root-container">
    <span class="input-header">{{title}}</span>
    <Input
      :value="value"
      :size="size"
      :type="type"
      :placeholder="placeholder"
      :maxlength="maxlength"
      :disabled="disabled"
      :icon="icon"
      :autosize="autosize"
      :rows="rows"
      :readonly="readonly"
      :name="name"
      :number="number"
      :autofocus="autofocus"
      :spellcheck="spellcheck"
      :autocomplete="autocomplete"
      :clearable="clearable"
      :elementId="elementId"
      :wrap="wrap"
      :prefix="prefix"
      :suffix="suffix"
      :search="search"
      :enterButton="enterButton"
      :showWordLimit="showWordLimit"
      :password="password"
      :title="title"
      style="width:100%"
      @on-change="change"
    />
    <div class="input-error-container">
      <transition name="fade">
        <Tooltip
          max-width="300"
          :disabled="toolTipDisabled"
          :content="errorText"
          style="max-width:100%"
        >
          <span ref="error" v-if="showError" class="input-error">{{errorText}}</span>
        </Tooltip>
      </transition>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    validate: Function,
    value: {
      type: [String, Number],
      default: "",
    },
    size: {
      type: String,
      default: "default",
    },
    placeholder: {
      type: String,
      default: "",
    },
    maxlength: {
      type: [String, Number],
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    icon: String,
    autosize: {
      type: [Boolean, Object],
      default: false,
    },
    rows: {
      type: Number,
      default: 2,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    name: {
      type: String,
    },
    number: {
      type: Boolean,
      default: false,
    },
    autofocus: {
      type: Boolean,
      default: false,
    },
    spellcheck: {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: "off",
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    elementId: {
      type: String,
    },
    wrap: {
      type: String,
      default: "soft",
    },
    prefix: {
      type: String,
      default: "",
    },
    suffix: {
      type: String,
      default: "",
    },
    search: {
      type: Boolean,
      default: false,
    },
    enterButton: {
      type: [Boolean, String],
      default: false,
    },
    showWordLimit: {
      type: Boolean,
      default: false,
    },
    password: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: "text",
    },
  },
  data() {
    return {
      showError: false,
      errorText: "",
      toolTipDisabled: true,
    };
  },
  mounted() {},
  watch: {
    errorText: function (newValue, oldValue) {
      if (newValue == "") {
        this.toolTipDisabled = true;
      } else {
        this.$nextTick(() => {
          if (this.$refs.error.offsetWidth >= this.$refs.root.offsetWidth) {
            this.toolTipDisabled = false;
          } else {
            this.toolTipDisabled = true;
          }
        });
      }
    },
  },
  methods: {
    change(event) {
      this.$emit("input", event.target.value);
    },
    doValidate() {
      return new Promise((resolve, reject) => {
        if (this.validate) {
          this.validate(this.value)
            .then((res) => {
              this.errorText = "";
              this.showError = false;
              resolve();
            })
            .catch((error) => {
              this.errorText = error;
              this.showError = true;
              reject();
            });
        } else {
          resolve();
        }
      });
    },
    clearValidate() {
      this.errorText = "";
      this.showError = false;
    },
  },
};
</script>

<style>
.input-root-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.input-header {
  font-weight: bold;
  margin-bottom: 10px;
}
.input-error-container {
  height: 14px;
  margin-top: 4px;
}
.input-error {
  display: block;
  color: #f60;
  font-size: 12px;
  line-height: 14px;
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.input-error-container .ivu-tooltip-rel {
  display: block;
  max-width: 100%;
}
</style>