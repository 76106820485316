<template>
  <div ref="root" class="image-root-container">
    <Upload
      class="image-upload-container"
      ref="upload"
      :show-upload-list="false"
      accept="image/gif, image/jpeg, image/jpg, image/png, image/svg"
      :format="['jpg','jpeg','png','gif','svg']"
      :on-format-error="onFormatError"
      :before-upload="beforeUpload"
      type="drag"
      action
    >
      <div v-if="!imgUrl" key="1" class="image-upload-inner-container">
        <Icon type="ios-camera" size="40"></Icon>
      </div>
      <div
        v-else
        key="2"
        class="image-upload-inner-container"
        :style="{'background-image': 'url('+imgUrl+')'}"
      >
        <div class="image-upload-item-cover">
          <slot>
            <Icon
              type="ios-eye"
              :size="40"
              color="#fff"
              style="margin-right:5px;"
              @click.stop="viewImage"
            ></Icon>
            <Icon type="md-cloud-upload" :size="30" color="#fff"></Icon>
          </slot>
        </div>
      </div>
    </Upload>
    <div class="image-error-container">
      <transition name="fade">
        <Tooltip
          max-width="300"
          :disabled="toolTipDisabled"
          :content="errorText"
          style="max-width:100%"
        >
          <span ref="error" v-if="showError" class="image-error">{{errorText}}</span>
        </Tooltip>
      </transition>
    </div>
    <image-modal ref="imageModal" />
  </div>
</template>

<script>
import ImageModal from "../../components/Modal/ImageModal";
export default {
  components: {
    "image-modal": ImageModal
  },
  props: {
    beforeUpload: Function,
    imgUrl: String,
    validate: Function
  },
  data() {
    return {
      showError: false,
      errorText: "",
      toolTipDisabled: true
    };
  },
  mounted() {},
  watch: {
    errorText: function(newValue, oldValue) {
      if (newValue == "") {
        this.toolTipDisabled = true;
      } else {
        this.$nextTick(() => {
          if (this.$refs.error.offsetWidth >= this.$refs.root.offsetWidth) {
            this.toolTipDisabled = false;
          } else {
            this.toolTipDisabled = true;
          }
        });
      }
    }
  },
  methods: {
    viewImage() {
      this.$refs.imageModal.open(this.imgUrl);
    },
    onFormatError(file) {
      this.$Notice.warning({
        title: "文件格式错误",
        desc: ""
      });
    },
    doValidate() {
      return new Promise((resolve, reject) => {
        if (this.validate) {
          this.validate(this.imgUrl)
            .then(res => {
              this.errorText = "";
              this.showError = false;
              resolve();
            })
            .catch(error => {
              this.errorText = error;
              this.showError = true;
              reject();
            });
        } else {
          resolve();
        }
      });
    },
    clearValidate() {
      this.errorText = "";
      this.showError = false;
    }
  }
};
</script>

<style>
.image-root-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
.image-error-container {
  height: 14px;
  margin-top: 4px;
}
.image-error {
  display: block;
  color: #f60;
  font-size: 12px;
  line-height: 14px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
/*********************************************/
.image-upload-container {
  display: block;
  height: 100%;
  width: 100%;
}
.image-upload-inner-container {
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
  border-radius: 4px;
  overflow: hidden;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
/*********************************************/
.image-upload-item-cover {
  display: none;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
}
.image-upload-inner-container:hover .image-upload-item-cover {
  display: flex;
}

.image-error-container .ivu-tooltip-rel {
  display: block;
  max-width: 100%;
}
</style>