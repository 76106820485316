<template>
  <Modal title="图片查看" v-model="modalVisible" draggable footer-hide>
    <img :src="imgUrl" class="image-modal-main-container" />
  </Modal>
</template>

<script>
export default {
  data() {
    return {
      modalVisible: false,
      imgUrl: null
    };
  },
  mounted() {},
  methods: {
    open(imgUrl) {
      this.imgUrl = imgUrl;
      this.modalVisible = true;
    }
  }
};
</script>

<style scoped>
.image-modal-main-container {
  width: 100%;
  padding: 20px;
}
</style>