import * as type from '../type';
import * as trade from '../../api/trade';

const state = {}

const actions = {
  tradeGetCount({
    state,
    commit
  }, payload) {
    return new Promise((resolve, reject) => {
      trade.getCount(
        payload.employer_id
      ).then(res => {
        if (res.data.res_code == 1) {
          resolve(res.data.msg);
        } else {
          reject(res.data.msg);
        }
      }).catch(error => {
        console.error(error);
        reject();
      })
    })
  },
}

const mutations = {}

export default {
  state,
  actions,
  mutations
}